import { useIsBlogFiltersPopupOpenedValue, useSetOpenedPopupsState } from '@/atoms/opened-popups';
import Popup from '@/components/shared/Popup';
import { useRouter } from 'next/router';
import { m as motion } from 'framer-motion';
import Button from '@/components/shared/Button';
import FilterCheckbox from '@/components/shared/FilterCheckbox';
import { removeNullishAndUndefinedFromObject } from '@/utils/objects/index';
import { ApiArticleTag } from '@/api/tags';
import { useTagsStateContext } from './BlogFilters';
import { addUniqueItemToArray } from '@/utils/arrays/add-unique-item-to-array';

export const BLOG_FILTERS_POPUP_NAME = 'blog-filters';

interface Props {
    tags: ApiArticleTag[];
    totalCount: number;
    portal?: Element | DocumentFragment;
}

const BlogFiltersPopup = ({ tags, totalCount, portal }: Props) => {
    const isOpened = useIsBlogFiltersPopupOpenedValue();
    const { closePopup } = useSetOpenedPopupsState();
    const router = useRouter();
    const { tagsState } = useTagsStateContext();

    return (
        <Popup name={BLOG_FILTERS_POPUP_NAME} className="sort-popup" overlay portalTarget={portal}>
            <motion.div
                variants={{
                    visible: {
                        transition: {
                            staggerChildren: 0.07,
                            duration: 0.5,
                        },
                        opacity: 1,
                        y: 0,
                    },
                    hidden: {
                        opacity: 0,
                        y: 20,
                    },
                }}
                className="sort-popup__inner"
                initial="hidden"
                animate={isOpened ? 'visible' : 'hidden'}
            >
                <div className="sort-popup__main">
                    <div className="catalog-popup-header">
                        <div className="catalog-popup-title text-xs">фильтр</div>
                        <Button
                            geometryVariant="square-bracket"
                            onClick={() => {
                                closePopup(BLOG_FILTERS_POPUP_NAME);
                            }}
                            aria-label="Закрыть фильтр"
                            className="catalog-popup-close close-button"
                        >
                            X
                        </Button>
                    </div>

                    <div className="sort-popup__body">
                        <FilterCheckbox
                            name="sort"
                            value="default"
                            type="radio"
                            checked={tagsState.length === 0 || tagsState.length === tags.length}
                            onChange={() => {
                                router.push(
                                    {
                                        pathname: router.pathname,
                                        query: removeNullishAndUndefinedFromObject({
                                            ...router.query,
                                            'tags[]': [],
                                        }),
                                    },
                                    undefined,
                                    { shallow: true },
                                );
                                closePopup(BLOG_FILTERS_POPUP_NAME);
                            }}
                        >
                            {`Все [ ${totalCount} ]`}
                        </FilterCheckbox>
                        {tags.map((tag, i) => {
                            return (
                                <FilterCheckbox
                                    name="tags[]"
                                    value={tag.id.toString()}
                                    checked={tagsState.includes(tag.id.toString())}
                                    onChange={(arg: boolean) => {
                                        router.push(
                                            {
                                                pathname: router.pathname,
                                                query: removeNullishAndUndefinedFromObject({
                                                    ...router.query,
                                                    'tags[]': arg
                                                        ? addUniqueItemToArray(tagsState, tag.id.toString())
                                                        : tagsState.filter(
                                                              (prevItem) => prevItem !== tag.id.toString(),
                                                          ),
                                                }),
                                            },
                                            undefined,
                                            { shallow: true },
                                        );
                                        closePopup(BLOG_FILTERS_POPUP_NAME);
                                    }}
                                    key={i}
                                >
                                    {tag.name}
                                </FilterCheckbox>
                            );
                        })}
                    </div>
                </div>
            </motion.div>
        </Popup>
    );
};

export default BlogFiltersPopup;
