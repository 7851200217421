import { atom, useRecoilValue, useSetRecoilState } from 'recoil';

const allFiltersWidthState = atom<number>({
    key: 'allFiltersWidthState',
    default: 0,
});

export const useAllFiltersWidthValue = () => useRecoilValue(allFiltersWidthState);

export const useSetAllFiltersWidthState = () => useSetRecoilState(allFiltersWidthState);
