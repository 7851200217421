import { useState } from 'react';
import { useIsomorphicLayoutEffect } from 'usehooks-ts';

export function useIsClient() {
    const [isClient, setIsClient] = useState(false);

    useIsomorphicLayoutEffect(() => {
        setIsClient(true);
    }, []);

    return isClient;
}
