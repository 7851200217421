import { useFiltersState } from '@/atoms/catalog/filters-state';
import FilterCheckbox from '@/components/shared/FilterCheckbox';
import { PresetFilterItem } from '@/types';
import { produce } from 'immer';
import { Dispatch, SetStateAction } from 'react';

type PresetFilterField = 'price' | 'diameter' | 'power';

type Props = {
    defaultValue: { min: number | null; max: number | null };
    presetFilters: PresetFilterItem[];
    type: PresetFilterField;
    setCurrentValue?: Dispatch<SetStateAction<{ minValue: number | null; maxValue: number | null }>>;
};

const filterMapping: Record<
    PresetFilterField,
    {
        min: 'priceMin' | 'diameterMin' | 'powerMin';

        max: 'priceMax' | 'diameterMax' | 'powerMax';
    }
> = {
    price: {
        min: 'priceMin',
        max: 'priceMax',
    },
    diameter: {
        min: 'diameterMin',
        max: 'diameterMax',
    },
    power: {
        min: 'powerMin',
        max: 'powerMax',
    },
};

const PresetFiltersList = ({ defaultValue, presetFilters, type, setCurrentValue }: Props) => {
    const [filtersState, setFiltersState] = useFiltersState();

    return (
        <div className="filter-row__list">
            {presetFilters.map((filter, i) => {
                if (filter.field === type) {
                    const valueMin = filter.min ?? defaultValue.min;
                    const valueMax = filter.max ?? defaultValue.max;
                    const isChecked =
                        filtersState[filterMapping[type].min] === valueMin &&
                        filtersState[filterMapping[type].max] === valueMax;

                    return (
                        <FilterCheckbox
                            key={i}
                            name={type}
                            value={i.toString()}
                            checked={isChecked}
                            onChange={(arg) => {
                                if (setCurrentValue) {
                                    isChecked
                                        ? setCurrentValue({
                                              minValue: defaultValue.min,
                                              maxValue: defaultValue.max,
                                          })
                                        : setCurrentValue({
                                              minValue: valueMin,
                                              maxValue: valueMax,
                                          });
                                }
                                setFiltersState(
                                    produce((draft) => {
                                        if (filterMapping[type]) {
                                            const { min, max } = filterMapping[type];
                                            if (arg) {
                                                draft[min] = valueMin ?? undefined;
                                                draft[max] = valueMax ?? undefined;
                                            } else {
                                                draft[min] = undefined;
                                                draft[max] = undefined;
                                            }
                                        }
                                    }),
                                );
                            }}
                        >
                            {filter.name}
                        </FilterCheckbox>
                    );
                }
            })}
        </div>
    );
};

export default PresetFiltersList;
