import { AnimatePresence, m } from 'framer-motion';
import { easeOutQuart } from '@/utils/easings';
import Button from '@/components/shared/Button';
import { useState, FormEvent, useRef, useEffect } from 'react';
import Input from '@/components/shared/Input';
import SearchSVG from '@/svg/search.svg';
import FilterCheckbox from '@/components/shared/FilterCheckbox';
import { useFiltersState } from '@/atoms/catalog/filters-state';
import { produce } from 'immer';
import { useMediaQueryDeviceState } from '@/atoms/media-query-device';
import { useAllFiltersShowState } from '@/atoms/catalog/all-filters';
import ArrowAllBrandsSVG from '@/svg/arrow-all-brands.svg';
import { FilterList } from '@/types';
import { isStringArray } from '@/utils/arrays/array-string-type-check';

interface Props {
    filterList: FilterList;
}

const AllFilters = ({ filterList }: Props) => {
    const { filters, type } = filterList;

    const [showAllFilters, setShowAllFilters] = useAllFiltersShowState();
    const [searchedFilters, setSearchedFilters] = useState(filters);
    const [filtersState, setFiltersState] = useFiltersState();
    const allFiltersRef = useRef<HTMLDivElement>(null);
    const [device] = useMediaQueryDeviceState();
    const [filtersInputValue, setFiltersInputValue] = useState('');

    useEffect(() => {
        setFiltersInputValue('');
        setSearchedFilters(filters);
    }, [filters]);

    return (
        <AnimatePresence>
            {showAllFilters && (
                <m.div
                    className="all-brands js-all-brands-width js-popup-inner"
                    initial={{
                        x: device === 'desktop' ? '-100%' : '100%',
                        opacity: 0,
                    }}
                    exit={{
                        x: '-100%',
                        opacity: 0,
                    }}
                    animate={{
                        x: 0,
                        opacity: 1,
                    }}
                    transition={{
                        ease: easeOutQuart,
                        duration: 0.6,
                    }}
                    ref={allFiltersRef}
                >
                    <Button
                        geometryVariant="square-bracket"
                        className="all-brands-close close-button"
                        onClick={() => {
                            setShowAllFilters(false);
                        }}
                    >
                        x
                    </Button>
                    <button
                        className="all-brands-button-brand"
                        onClick={() => {
                            setShowAllFilters(false);
                        }}
                    >
                        <div className="all-brands-button-brand__icon">
                            <ArrowAllBrandsSVG />
                        </div>
                        <div className="all-brands-button-brand__text">Назад</div>
                    </button>
                    <div className="all-brands-search">
                        <Input
                            label="поиск"
                            icon={<SearchSVG />}
                            variant="white"
                            onInput={(event: FormEvent<HTMLInputElement>) => {
                                const eventTarget = event.currentTarget;
                                isStringArray(filters)
                                    ? setSearchedFilters(
                                          filters.filter((item) => {
                                              return item.toLowerCase().includes(eventTarget.value.toLowerCase());
                                          }),
                                      )
                                    : setSearchedFilters(
                                          filters.filter((item) => {
                                              return item.name.toLowerCase().includes(eventTarget.value.toLowerCase());
                                          }),
                                      );
                                setFiltersInputValue(eventTarget.value);
                            }}
                            value={filtersInputValue}
                            className="all-brands-search__input"
                        />
                    </div>
                    <div className="all-brands-list">
                        {isStringArray(searchedFilters)
                            ? searchedFilters.map((filter, i) => {
                                  return (
                                      filter && (
                                          <FilterCheckbox
                                              key={i}
                                              name={filter}
                                              value={filter}
                                              checked={
                                                  !!filtersState[type] &&
                                                  // @ts-ignore скоро исправлю
                                                  filtersState[type].length > 0 &&
                                                  // @ts-ignore скоро исправлю
                                                  !!filtersState[type].find((item) => item === filter)
                                              }
                                              onChange={(arg) => {
                                                  setFiltersState(
                                                      produce((draft) => {
                                                          if (arg) {
                                                              // @ts-ignore скоро исправлю
                                                              draft[type]?.push(filter);
                                                          } else {
                                                              // @ts-ignore скоро исправлю
                                                              draft[type] = draft[type]?.filter(
                                                                  (item) => item !== filter,
                                                              );
                                                          }
                                                      }),
                                                  );
                                              }}
                                          >
                                              {filter}
                                          </FilterCheckbox>
                                      )
                                  );
                              })
                            : searchedFilters.map((filter, i) => {
                                  return (
                                      filter && (
                                          <FilterCheckbox
                                              key={i}
                                              name={filter.name}
                                              value={filter.id.toString()}
                                              bordered={'highlighted' in filter && filter.highlighted}
                                              checked={
                                                  !!filtersState[type] &&
                                                  // @ts-ignore скоро исправлю
                                                  filtersState[type].length > 0 &&
                                                  // @ts-ignore скоро исправлю
                                                  !!filtersState[type].find((item) => item === filter.id)
                                              }
                                              onChange={(arg) => {
                                                  setFiltersState(
                                                      produce((draft) => {
                                                          if (arg) {
                                                              // @ts-ignore скоро исправлю
                                                              draft[type]?.push(filter.id);
                                                          } else {
                                                              // @ts-ignore скоро исправлю
                                                              draft[type] = draft[type]?.filter(
                                                                  (item) => item !== filter.id,
                                                              );
                                                          }
                                                      }),
                                                  );
                                              }}
                                          >
                                              {filter.name}
                                          </FilterCheckbox>
                                      )
                                  );
                              })}
                    </div>
                </m.div>
            )}
        </AnimatePresence>
    );
};

export default AllFilters;
