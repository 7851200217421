import { useIsSortPopupOpenedValue, useSetOpenedPopupsState } from '@/atoms/opened-popups';
import Popup from '@/components/shared/Popup';
import classNames from 'classnames';
import { useRouter } from 'next/router';
import { useState } from 'react';
import { m } from 'framer-motion';
import Button from '@/components/shared/Button';
import FilterCheckbox from '@/components/shared/FilterCheckbox';
import { removeNullishAndUndefinedFromObject } from '@/utils/objects/index';

export const SORT_POPUP_NAME = 'catalog-sort';

type SortMethod = {
    name: string;
    value: string;
    postfix: string;
};

interface Props {
    methods: SortMethod[];
    portal?: Element | DocumentFragment;
}

const SortPopup = ({ methods, portal }: Props) => {
    const isOpened = useIsSortPopupOpenedValue();
    const { closePopup } = useSetOpenedPopupsState();
    const router = useRouter();

    const handleClick = (sort?: string, order?: string) => {
        router.push(
            {
                pathname: router.pathname,
                query: {
                    ...removeNullishAndUndefinedFromObject({
                        ...router.query,
                        sort,
                        order,
                    }),
                },
            },
            undefined,
            { shallow: true },
        );
        closePopup(SORT_POPUP_NAME);
    };

    return (
        <Popup name={SORT_POPUP_NAME} className="sort-popup" overlay portalTarget={portal}>
            <m.div
                variants={{
                    visible: {
                        transition: {
                            staggerChildren: 0.07,
                            duration: 0.5,
                        },
                        opacity: 1,
                        y: 0,
                    },
                    hidden: {
                        opacity: 0,
                        y: 20,
                    },
                }}
                className="sort-popup__inner"
                initial="hidden"
                animate={isOpened ? 'visible' : 'hidden'}
            >
                <div className="sort-popup__main">
                    <div className="catalog-popup-header">
                        <div className="catalog-popup-title text-xs">сортировка</div>
                        <Button
                            geometryVariant="square-bracket"
                            onClick={() => {
                                closePopup(SORT_POPUP_NAME);
                            }}
                            aria-label="Закрыть сортировку"
                            className="catalog-popup-close close-button"
                        >
                            X
                        </Button>
                    </div>

                    <div className="sort-popup__body">
                        {methods.map((method, i) => {
                            return (
                                <FilterCheckbox
                                    name="sort"
                                    value={method.value}
                                    type="radio"
                                    checked={
                                        router.query.sort === method.value && router.query.order === method.postfix
                                    }
                                    onChange={() => {
                                        handleClick(method.value, method.postfix);
                                    }}
                                    key={i}
                                >
                                    {method.name}
                                </FilterCheckbox>
                            );
                        })}
                    </div>
                </div>
            </m.div>
        </Popup>
    );
};

export default SortPopup;
