import { useIsFeedbackPopupOpenedValue, useSetOpenedPopupsState } from '@/atoms/opened-popups';
import { m, AnimatePresence, useIsomorphicLayoutEffect } from 'framer-motion';
import { useEffect, useRef, useState } from 'react';
import { useSetSelectedServiceValueFormState } from '@/atoms/form/selected-service-value-form-state';
import { usePopupInnerHeightState } from '@/atoms/form/popup-height';
import { easeOutQuart } from '@/utils/easings';
import { useFormStepState } from '@/atoms/form/step-state';
import dynamic from 'next/dynamic';
import Button from '@/components/shared/Button';
import { tp } from '@/typograf';
import { FeedbackPopupDataName, useFeedbackPopupDataNameValue } from './use-feedback-popup-data-name-atom';

const Popup = dynamic(() => import('@/components/shared/Popup'));
const ConnectStep = dynamic(() => import('./ConnectStep'));
const ServiceStep = dynamic(() => import('./ServiceStep'));
const FormStep = dynamic(() => import('./FormStep'));

export const FEEDBACK_POPUP_NAME = 'feedback-popup';

export type StepName = 'connect' | 'service' | 'form';

const data: Record<FeedbackPopupDataName, { title: string; text: string } | null> = {
    empty: null,
    'trade-in': {
        title: tp('trade-in'),
        text: tp(`
            <p>С услугой trade-in вы легко решаете все свои задачи. Получите желаемое изделие в кратчайшие сроки, не задумываясь о том, где и кому продать свои старые часы или ювелирные украшения. Наша служба trade-in подготовит предложение без долгих ожиданий.</p>
        `),
    },
    order: {
        title: tp('Сервис «Под заказ»'),
        text: tp(`
            <p>Мы — одни из немногих в России, кто предоставляет гарантию на свои услуги.</p>
            <p>Наша команда — это профессионалы в области поиска эксклюзивных изделий по лучшим ценам.</p>
            <ul>
                <li>
                    Отправьте скриншот изделия, которое Вы бы хотели заказать нашим персональным менеджерам в WhatsApp или Telegram. Вы не ограничены ассортиментом, представленным в нашем сайте!
                </li>
                <li>
                    Далее менеджер произведет уточнения нужных позиций для Вас с помощью наших источников в бутиках, либо у дилеров, которые работают в разных странах.
                </li>
                <li>
                    Наш менеджер договаривается о сроках доставки и рассказывает Вам подробную информацию об изделии, после чего Вы можете забрать свой заказ у нас в офисе в Москве.
                </li>
            </ul>
            `),
    },
    sell: {
        title: tp('Продать'),
        text: tp(`
            <p>У нас имеется обширная база клиентов, и мы сотрудничаем с аукционными домами по всему миру, что позволяет нам предложить лучшую цену.</p>
        `),
    },
    cheaper: {
        title: tp('Нашли дешевле'),
        text: tp(`
            <p>Напишите менеджеру, чтобы обсудить скидку!</p>
        `),
    },
};

const FeedbackPopup = () => {
    const { closePopup } = useSetOpenedPopupsState();
    const isOpened = useIsFeedbackPopupOpenedValue();
    const setSelectedServiceValueFormState = useSetSelectedServiceValueFormState();
    const el = useRef<HTMLDivElement>(null);
    const hint = useRef<HTMLDivElement>(null);
    const [hintSize, setHintSize] = useState({ height: 0 });
    const closeButton = useRef<HTMLButtonElement>(null);
    const [popupInnerHeight] = usePopupInnerHeightState();
    const [popupOffset, setPopupOffset] = useState(0);
    const popupHeight = popupInnerHeight ? popupInnerHeight + popupOffset + hintSize.height : 'auto';
    const [step, setStep] = useFormStepState();
    const activeDataName = useFeedbackPopupDataNameValue();
    const activeDate = data[activeDataName];

    useEffect(() => {
        if (hint.current && isOpened) {
            setHintSize({ height: hint.current.offsetHeight });
        }
    }, [activeDataName, isOpened]);

    useEffect(() => {
        if (!isOpened) {
            setSelectedServiceValueFormState(null);
            setStep('connect');
        }
    }, [isOpened, setSelectedServiceValueFormState, setStep]);

    useIsomorphicLayoutEffect(() => {
        const onResize = () => {
            if (el.current && closeButton.current) {
                setPopupOffset(
                    parseInt(window.getComputedStyle(el.current).getPropertyValue('padding-top'), 10) +
                        parseInt(window.getComputedStyle(el.current).getPropertyValue('padding-bottom'), 10),
                );
            }
        };

        window.addEventListener('resize', onResize);
        onResize();

        return () => {
            window.removeEventListener('resize', onResize);
        };
    }, [isOpened]);

    return (
        <Popup name={FEEDBACK_POPUP_NAME} className="feedback-popup" overlay>
            <AnimatePresence>
                {isOpened && (
                    <m.div
                        key={activeDataName}
                        className="feedback-popup__body"
                        initial={{ height: 0 }}
                        animate={{ height: popupHeight }}
                        exit={{ height: 0 }}
                        transition={{
                            duration: 0.6,
                            ease: easeOutQuart,
                        }}
                    >
                        <m.div className="feedback-popup__hint" ref={hint}>
                            <Button
                                geometryVariant="square-bracket"
                                onClick={() => {
                                    closePopup(FEEDBACK_POPUP_NAME);
                                }}
                                aria-label="Закрыть форму обратной связи"
                                className="feedback-popup-close-button close-button"
                                ref={closeButton}
                            >
                                X
                            </Button>
                            {activeDate?.title && (
                                <m.div
                                    initial={{ opacity: 0 }}
                                    animate={{ opacity: 1 }}
                                    exit={{ opacity: 0 }}
                                    transition={{ delay: 0.7, duration: 0.7 }}
                                    className="hint-title m-text-xs"
                                    dangerouslySetInnerHTML={{ __html: activeDate.title }}
                                />
                            )}
                            {activeDate?.text && (
                                <m.div
                                    initial={{ opacity: 0 }}
                                    animate={{ opacity: 1 }}
                                    exit={{ opacity: 0 }}
                                    transition={{ delay: 0.75, duration: 0.7 }}
                                    className="hint-description text-l wysiwyg"
                                    dangerouslySetInnerHTML={{ __html: activeDate.text }}
                                ></m.div>
                            )}
                        </m.div>
                        <div className="feedback-popup__inner" ref={el}>
                            <AnimatePresence mode="wait">
                                {step === 'connect' && (
                                    <ConnectStep
                                        callback={(arg: StepName) => {
                                            setStep(arg);
                                        }}
                                    />
                                )}
                                {step === 'service' && (
                                    <ServiceStep
                                        callback={(arg: StepName) => {
                                            setStep(arg);
                                        }}
                                    />
                                )}
                                {step === 'form' && (
                                    <FormStep
                                        callback={(arg: StepName) => {
                                            setStep(arg);
                                        }}
                                    />
                                )}
                            </AnimatePresence>
                        </div>
                    </m.div>
                )}
            </AnimatePresence>
        </Popup>
    );
};

export default FeedbackPopup;
