import { forwardRef, useEffect, FormEvent, useState } from 'react';
import Input from '../Input';
import { Props as InputProps } from '../Input/Input';
import { clamp } from '@/utils/clamp';
import classNames from 'classnames';

interface Props extends InputProps {
    min: number;
    max: number;
    value: number;
    defaultValue: number;
    prefix?: string;
    suffix?: string;
    handleChange: (value: number) => void;
    handleBlur: (value: number) => void;
}

const TextInput = forwardRef<HTMLInputElement, Props>(
    ({ value, min, max, defaultValue, prefix = '', suffix = '', handleChange, handleBlur, ...props }, ref) => {
        const [currentValue, setCurrentValue] = useState<number | ''>(defaultValue);
        const [isFocused, setIsFocused] = useState(false);

        useEffect(() => {
            if (!isNaN(value)) {
                setCurrentValue(value);
            }
        }, [value]);

        return (
            <label
                className={classNames('range-slider-text', {
                    'is-focused': isFocused,
                })}
            >
                <span className="range-slider-info__bracket range-slider-info__bracket--left">{'['}</span>&nbsp;
                <Input
                    {...props}
                    ref={ref}
                    tag="span"
                    value={currentValue}
                    prefix={prefix}
                    suffix={suffix}
                    className="range-slider-text-input"
                    onBlur={(event: FormEvent) => {
                        const eventTarget = event.target as HTMLInputElement;
                        if (currentValue !== '') {
                            setCurrentValue(clamp(currentValue, min, max));
                        } else {
                            setCurrentValue(defaultValue);
                        }
                        setIsFocused(false);

                        if (eventTarget) {
                            const value = eventTarget.value.replace('$ ', '').replace(/^0/, '');
                            const parsedValue = parseInt(value, 10);

                            if (!isNaN(parsedValue)) {
                                handleBlur(parsedValue);
                            }
                        }
                    }}
                    onChange={(event: FormEvent) => {
                        const eventTarget = event.target as HTMLInputElement;
                        if (eventTarget) {
                            const value = eventTarget.value.replace('$ ', '').replace(/^0/, '');
                            const parsedValue = parseInt(value, 10);

                            if (!isNaN(parsedValue)) {
                                handleChange(parsedValue);
                                setCurrentValue(parsedValue);
                            } else {
                                setCurrentValue('');
                            }
                        }
                    }}
                    onFocus={() => {
                        setIsFocused(true);
                    }}
                    style={{
                        width: `${currentValue ? clamp(currentValue.toString().length + prefix.length + suffix.length, 5, 10) : 5}ch`,
                    }}
                />
                &nbsp;
                <span className="range-slider-info__bracket range-slider-info__bracket--right">{']'}</span>
            </label>
        );
    },
);

TextInput.displayName = 'TextInput';

export default TextInput;
