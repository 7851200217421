import { atom, useRecoilValue, useSetRecoilState } from 'recoil';

export type FeedbackPopupDataName = 'empty' | 'trade-in' | 'order' | 'sell' | 'cheaper';

const feedbackPopupDataNameState = atom<FeedbackPopupDataName>({
    key: 'feedbackPopupDataNameState',
    default: 'empty',
});

export const useFeedbackPopupDataNameValue = () => useRecoilValue(feedbackPopupDataNameState);

export const useSetFeedbackPopupDataNameState = () => useSetRecoilState(feedbackPopupDataNameState);
