import { PresetFilterItem } from '@/types';
import { atom, useRecoilValue, useSetRecoilState } from 'recoil';

const diameterFiltersState = atom<{ label: string; filters: PresetFilterItem[] }>({
    key: 'diameterFiltersState',
    default: {
        label: 'Популярные размеры',
        filters: [],
    },
});

export const useDiameterFiltersValue = () => useRecoilValue(diameterFiltersState);
export const useSetDiameterFiltersState = () => useSetRecoilState(diameterFiltersState);
