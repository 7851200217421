import classNames from 'classnames';
import {
    ButtonHTMLAttributes,
    Dispatch,
    HTMLAttributes,
    SetStateAction,
    createContext,
    useCallback,
    useContext,
    useEffect,
    useRef,
    useState,
} from 'react';
import useIsomorphicLayoutEffect from '@/hooks/use-isomorphic-layout-effect';
import { usePrevious } from '@/hooks/use-previous';
import { useIsFirstRender } from 'usehooks-ts';
import { getCSSCustomProp } from '@/utils/css';

interface Props extends HTMLAttributes<HTMLElement> {
    collapsed?: boolean;
    defaultCollapsed?: boolean;
    onCollapse?: () => void;
    onExpand?: () => void;
}

interface TogglerProps extends ButtonHTMLAttributes<HTMLButtonElement> {
    onToggle?: (event: React.MouseEvent<HTMLButtonElement>) => void;
}

const CollapseContext = createContext<{ collapsed: boolean; setCollapsed: Dispatch<SetStateAction<boolean>> }>({
    collapsed: true,
    setCollapsed: () => {},
});

export const useCollapseContext = () => useContext(CollapseContext);

const Toggler = ({ onToggle, ...props }: TogglerProps) => {
    const { collapsed, setCollapsed } = useContext(CollapseContext);

    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setCollapsed(!collapsed);
        onToggle?.(event);
    };

    return (
        <button
            {...props}
            className={classNames('collapse__toggler', props.className)}
            onClick={handleClick}
            aria-expanded={!collapsed}
        ></button>
    );
};

const Content = ({ children }: HTMLAttributes<HTMLElement>) => {
    const content = useRef<HTMLDivElement>(null);
    const { collapsed } = useCollapseContext();
    const [minVisibleHeight, setMinVisibleHeight] = useState(0);
    const [contentHeight, setContentHeight] = useState<number | string>(collapsed ? 0 : '');
    const isFirstRender = useIsFirstRender();

    const setUIState = useCallback(() => {
        if (content.current) {
            if (collapsed) {
                setContentHeight(Math.min(content.current.scrollHeight, minVisibleHeight));
            } else {
                setContentHeight(content.current.scrollHeight);
            }
        }
    }, [collapsed, minVisibleHeight]);

    useIsomorphicLayoutEffect(() => {
        setUIState();
    }, [setUIState]);

    useEffect(() => {
        let ro: ResizeObserver | null;

        if ('ResizeObserver' in window && content.current) {
            ro = new ResizeObserver((entries, observer) => {
                entries.forEach((entry) => {
                    observer.unobserve(entry.target);
                    setUIState();
                });
            });
            ro.observe(content.current);
        }

        return () => {
            if (ro) {
                ro.disconnect();
                ro = null;
            }
        };
    }, [setUIState]);

    useIsomorphicLayoutEffect(() => {
        const onResize = () => {
            if (content.current) {
                const parentCollapse = content.current.closest<HTMLElement>('.collapse');

                if (parentCollapse) {
                    const minHeight = getCSSCustomProp(parentCollapse, '--min-visible-height', 'number') as number;
                    setMinVisibleHeight(minHeight);
                }
            }
        };

        setTimeout(() => {
            if (content.current) {
                const innerCollapses = Array.from(content.current.querySelectorAll('.collapse'));
                innerCollapses.forEach((innerCollapse) => {
                    innerCollapse.addEventListener('transitionend', () => {
                        if (content.current) {
                            content.current.style.height = '';
                        }
                        document.dispatchEvent(new Event('collapse-update'));
                    });
                });
            }
        }, 100);

        const onUpdate = () => {
            if (content.current) {
                const innerCollapses = Array.from(content.current.querySelectorAll('.collapse'));
                if (innerCollapses.length > 0) {
                    setUIState();
                }
            }
        };

        setTimeout(() => {
            onResize();
        }, 100);
        window.addEventListener('resize', onResize);
        document.addEventListener('collapse-update', onUpdate);

        return () => {
            window.removeEventListener('resize', onResize);
            document.removeEventListener('collapse-update', onUpdate);
        };
    }, []);

    return (
        <div
            ref={content}
            className="collapse__content"
            style={{ height: isFirstRender && !collapsed ? undefined : contentHeight }}
        >
            {children}
        </div>
    );
};

const Collapse = ({ children, collapsed, defaultCollapsed = true, onCollapse, onExpand, ...props }: Props) => {
    const [innerCollapsed, setInnerCollapsed] = useState(defaultCollapsed);
    const prevInnerCollapsed = usePrevious(innerCollapsed);

    useEffect(() => {
        if (typeof collapsed !== 'undefined') {
            setInnerCollapsed(collapsed);
        }
    }, [collapsed]);

    useEffect(() => {
        if (typeof prevInnerCollapsed !== 'undefined') {
            if (innerCollapsed) {
                onCollapse?.();
            } else {
                onExpand?.();
            }
        }
    }, [prevInnerCollapsed, innerCollapsed, onCollapse, onExpand]);

    return (
        <CollapseContext.Provider value={{ collapsed: innerCollapsed, setCollapsed: setInnerCollapsed }}>
            <div
                {...props}
                className={classNames('collapse', props.className, { 'collapse--opened': !innerCollapsed })}
            >
                {children}
            </div>
        </CollapseContext.Provider>
    );
};

Collapse.Toggler = Toggler;
Collapse.Content = Content;

export default Collapse;
