import { axiosAPI } from './axios-instance';
import { ApiResponse, ImageShape } from '@/types';

export type ApiCategorySlug = 'accessory' | 'bag' | 'decoration' | 'watch' | 'chrome-hearts';

export type CategoryId = 1 | 2 | 3 | 4 | 5;

export type ApiCategoriesResponse = ApiResponse<
    {
        id: CategoryId;
        slug: ApiCategorySlug;
        name: string;
        lottie: string;
        default: boolean;
        image?: ImageShape | null;
    }[]
>;

export const CATEGORIES_IDS: Record<ApiCategorySlug, CategoryId> = {
    'chrome-hearts': 5,
    accessory: 4,
    bag: 2,
    decoration: 3,
    watch: 1,
} as const;

export const CATEGORIES_SLUGS: Record<CategoryId, ApiCategorySlug> = {
    5: 'chrome-hearts',
    4: 'accessory',
    2: 'bag',
    3: 'decoration',
    1: 'watch',
} as const;

export const getCategories = () => axiosAPI.get<ApiCategoriesResponse>(`/v1/categories`).then((res) => res.data);
export const getCategory = (id: CategoryId) =>
    axiosAPI.get<ApiCategoriesResponse>(`/v1/categories/${id}`).then((res) => res.data);

export const CATEGORIES_TEXT_DICT: Record<CategoryId, string> = {
    5: 'хромированные сердца',
    4: 'аксессуары',
    2: 'сумки',
    3: 'украшения',
    1: 'часы',
};
