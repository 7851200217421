import Button from '../Button';
import classNames from 'classnames';
import BrandStarSVG from '@/svg/brand-star.svg';

interface Props {
    children: string;
    name: string;
    value: string;
    type?: 'checkbox' | 'radio';
    bordered?: boolean;
    checked: boolean;
    onChange: (arg: boolean) => void;
}

const FilterCheckbox = ({ children, name, value, type = 'checkbox', bordered = false, checked, onChange }: Props) => {
    return (
        <label
            className={classNames('filter-checkbox m-text-xs', {
                'filter-checkbox--checked': checked,
                'filter-checkbox--bordered': bordered,
            })}
        >
            <Button tag="span" className="filter-checkbox__text m-text-xs">
                {bordered && <BrandStarSVG />}
                {children}
                <span className="filter-checkbox__cross" aria-hidden="true">
                    &nbsp;X
                </span>
            </Button>
            <input
                type={type}
                name={name}
                value={value}
                className="filter-checkbox__input"
                defaultChecked={checked}
                onClick={() => {
                    onChange(!checked);
                }}
            />
        </label>
    );
};

export default FilterCheckbox;
