import { useIsSearchPopupOpenedValue, useSetOpenedPopupsState } from '@/atoms/opened-popups';
import Popup from '@/components/shared/Popup';
import { useRef } from 'react';
import { m } from 'framer-motion';
import Button from '@/components/shared/Button';
import SearchInput from '@/components/shared/SearchInput';

export const SEARCH_POPUP_NAME = 'search';

const SearchPopup = () => {
    const { closePopup } = useSetOpenedPopupsState();
    const close = () => closePopup(SEARCH_POPUP_NAME);
    const isOpened = useIsSearchPopupOpenedValue();

    const searchInput = useRef<HTMLInputElement>(null);

    return (
        <Popup
            name={SEARCH_POPUP_NAME}
            className="search-popup"
            overlay
            onOpen={() => {
                if (searchInput.current) {
                    searchInput.current.focus();
                }
            }}
        >
            <m.div
                variants={{
                    visible: {
                        transition: {
                            staggerChildren: 0.07,
                            duration: 0.5,
                        },
                        opacity: 1,
                        y: 0,
                    },
                    hidden: {
                        opacity: 0,
                        y: 20,
                    },
                }}
                className="search-popup__inner"
                initial="hidden"
                animate={isOpened ? 'visible' : 'hidden'}
            >
                <div className="search-popup__main">
                    <div className="catalog-popup-header">
                        <div className="catalog-popup-title text-xs">поиск товара</div>
                        <Button
                            geometryVariant="square-bracket"
                            onClick={close}
                            aria-label="Закрыть сортировку"
                            className="catalog-popup-close close-button"
                        >
                            X
                        </Button>
                    </div>

                    <SearchInput ref={searchInput} className="popup-search" showLabel={false} onSubmit={close} />
                </div>
            </m.div>
        </Popup>
    );
};

export default SearchPopup;
