import Button from '@/components/shared/Button';
import Popup from '@/components/shared/Popup';
import { m } from 'framer-motion';
import { useIsCategoriesFiltersPopupOpenedValue, useSetOpenedPopupsState } from '@/atoms/opened-popups';
import { Category, Product } from '@/types';
import CategoryButton from '../CompareTabs/CategoryButton';

interface Props {
    products: Product[];
    categories: Category[];
}

export const FILTERS_CATEGORIES_POPUP_NAME = 'categories-filters';

const CategoriesFiltersPopup = ({ products, categories }: Props) => {
    const { closePopup } = useSetOpenedPopupsState();
    const isOpened = useIsCategoriesFiltersPopupOpenedValue();

    return (
        <Popup name={FILTERS_CATEGORIES_POPUP_NAME} className="filters-popup" overlay>
            <m.div
                className="filters-popup__inner"
                variants={{
                    visible: {
                        transition: {
                            staggerChildren: 0.07,
                            duration: 0.5,
                        },
                        opacity: 1,
                        y: 0,
                    },
                    hidden: {
                        opacity: 0,
                        y: 20,
                    },
                }}
                initial="hidden"
                animate={isOpened ? 'visible' : 'hidden'}
            >
                <div className="filters-popup__main">
                    <div className="catalog-popup-header">
                        <div className="catalog-popup-title text-xs">Фильтры</div>
                        <Button
                            geometryVariant="square-bracket"
                            onClick={() => {
                                closePopup(FILTERS_CATEGORIES_POPUP_NAME);
                            }}
                            aria-label="Закрыть фильтры"
                            className="catalog-popup-close close-button"
                        >
                            X
                        </Button>
                    </div>
                    <div className="categories-popup-content text-xs">
                        <CategoryButton products={products ?? []} viewed />
                        {categories.map((item) => {
                            return <CategoryButton products={products ?? []} item={item} key={item.id} viewed />;
                        })}
                    </div>
                </div>
            </m.div>
        </Popup>
    );
};

export default CategoriesFiltersPopup;
